<template>
  <div
    class="recharge-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/member_bg.jpg')" />
      <div class="credit">
        <span class="title">{{ PAGELANG.可提现收益 }}</span>
        <span class="price">{{ agentInfo.Withdrawal }}</span>
      </div>
    </div>

    <div class="credit-block">
      <van-form @submit="onSubmit">
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
            {{ PAGELANG.温馨提示 }}
          </div>
          <div class="van-cell__value van-field__value">
            <div
              class="van-field__body"
              style="width: 100%; color: #f66; text-align: left"
            >
              {{ PAGELANG.只能在每月1至5号申请提现 }}
            </div>
          </div>
        </div>
        <van-field
          v-model="Account"
          is-link
          readonly
          name="picker"
          :label="PAGELANG.提现账户"
          :placeholder="PAGELANG.选择提现账户"
          @click="showAccount = true"
          label-align="top"
          :rules="[{ required: true, message: PAGELANG.请选择一个提现账户 }]"
        />
        <van-popup v-model:show="showAccount" position="bottom">
          <van-picker
            name="AccountID"
            :title="PAGELANG.提现账户"
            :columns="agentInfo.AccountList"
            v-model="AccountID"
            @confirm="onConfirm"
            @cancel="showAccount = false"
          />
        </van-popup>

        <van-field
          name="Amount"
          v-model="Amount"
          :label="PAGELANG.提现金额"
          :placeholder="PAGELANG.请输入提现金额"
          :rules="[
            {
              required: true,
              validator: checkAmount,
              message: PAGELANG.提现金额不能为空,
            },
          ]"
          :formatter="formatter"
          label-align="top"
          readonly
        />

        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >{{ PAGELANG.申请提现 }}</van-button
        >
      </van-form>
    </div>
  </div>

  <div
    class="pc-my-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <MyMenu :agentInfo="agentInfo" pagename="income" />
    <div class="main">
      <div class="credit-info">
        <div class="item">
          <span class="num price">{{ agentInfo.Withdrawal }}</span>
          {{ PAGELANG.可提现收益 }}
        </div>
      </div>
      <el-form
        :label-width="labelWidth"
        class="withdraw-form"
        :model="withdraw"
        :rules="rules"
        ref="refWithdraw"
      >
        <el-form-item
          :label="PAGELANG.温馨提示"
          style="--el-text-color-regular: #f66"
        >
          <div class="van-field__body" style="width: 100%; color: #f66">
            {{ PAGELANG.只能在每月1至5号申请提现 }}
          </div>
        </el-form-item>

        <el-form-item :label="PAGELANG.提现账户" prop="AccountID">
          <el-select
            v-model="withdraw.AccountID"
            name="AccountID"
            size="large"
            :popper-append-to-body="false"
            :placeholder="PAGELANG.选择提现账户"
            style="width: 20vw"
          >
            <el-option
              v-for="item in agentInfo.AccountList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="PAGELANG.提现金额" prop="Amount">
          <el-input
            name="Amount"
            v-model="withdraw.Amount"
            :placeholder="PAGELANG.请输入提现金额"
            :formatter="formatter"
            style="width: 20vw"
            size="large"
            readonly
          />
        </el-form-item>

        <el-form-item>
          <el-button
            color="#000"
            class="btn-submit"
            style="width: 10vw"
            @click="onSubmitPC"
            size="large"
            >{{ PAGELANG.申请提现 }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      agentInfo: {},
      showAccount: false,
      Amount: "",
      rules: {},
      withdraw: {},
      labelWidth: "120px",
    };
  },
  beforeCreate() {
    if (!getCookie("agentloginid")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentloginid")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.income;
    this.SYSLANG = this.LANG.system;

    if (this.langcode != "zh") {
      this.labelWidth = "190px";
    }

    this.rules = {
      AccountID: [
        { required: true, message: this.PAGELANG.请选择一个提现账户 },
      ],
      Amount: [
        {
          required: true,
          validator: this.checkAmountPC,
        },
      ],
    };

    this.getMemberInfo();
  },
  methods: {
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .post(this.actions.income, {
          withdraw: 1,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, agentInfo } = data;
          console.log(code, msg);
          if (code == "SUCCESS") {
            this.agentInfo = agentInfo;
            this.Amount = agentInfo.Withdrawal.replaceAll(".", "");
            this.withdraw.Amount = agentInfo.Withdrawal.replaceAll(".", "");
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.IncomeWithdraw.getMemberInfo.error", error);
          closeToast();
          this.getMemberInfo();
        });
    },
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    formatter(val) {
      return val.replace(/[^\d]*/g, "").replace(/(?=(\B)(\d{3})+$)/g, ".");
    },
    onConfirm(val) {
      console.log(val);
      this.Account = val.selectedOptions[0].text;
      this.AccountID = val.selectedValues[0];
      this.showAccount = false;
    },
    checkAmount(val) {
      let curval = val.replaceAll(".", "");
      let curwithdrawal = this.agentInfo.Withdrawal.replaceAll(".", "");
      if (curval * 1 > curwithdrawal * 1) {
        return (
          this.PAGELANG.提现金额不能超过 +
          " " +
          this.SYSLANG.currency +
          this.agentInfo.Withdrawal
        );
      }
    },
    checkAmountPC(rule, value, callback) {
      console.log(rule, value);
      if (!value) {
        return callback(new Error(this.PAGELANG.提现金额不能为空));
      } else {
        let curval = value.replaceAll(".", "");
        let curwithdrawal = this.agentInfo.Withdrawal.replaceAll(".", "");
        if (curval * 1 > curwithdrawal * 1) {
          return callback(
            new Error(
              this.PAGELANG.提现金额不能超过 +
                " " +
                this.SYSLANG.currency +
                this.agentInfo.Withdrawal
            )
          );
        } else {
          return callback();
        }
      }
    },
    onSubmit() {
      let date = new Date().getDate();
      if (!((date >= 1 && date <= 5) || (date >= 16 && date <= 20))) {
        showToast({
          message: this.PAGELANG.只能在每月1至5号申请提现,
          forbidClick: true,
        });
        return;
      }

      showLoadingToast({
        message: this.SYSLANG.正在提交申请,
        duration: 0,
        forbidClick: true,
      });
      this.axios
        .post(this.actions.income_withdraw, {
          accountid: this.AccountID,
          Amount: this.Amount.replaceAll(".", ""),
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg } = data;
          showToast({
            message: this.PAGELANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "SUCCESS") {
                this.$router.back();
              }
            },
          });
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.IncomeWithdraw.onSubmit.error", error);
          closeToast();
          this.onSubmit();
        });
    },
    onSubmitPC() {
      this.$refs.refWithdraw.validate((valid) => {
        if (valid) {
          this.AccountID = this.withdraw.AccountID;
          this.Amount = this.withdraw.Amount;
          this.onSubmit();
        }
      });
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.pc-my-page {
  --el-color-primary: var(--van-card-price-color);
}
.el-select-dropdown__item.selected {
  color: var(--van-card-price-color);
}
</style>